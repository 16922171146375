import React from 'react'
import styled from 'styled-components'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

const StyledHowSafe = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`

const HowSafe = props => {
    return (
        <Layout path={props.location.pathname}>
            <BG />
            
            <SEO title="How Safe?" path={props.location.pathname} />

            <StyledHowSafe>
                <StyledSectionTitle>
                    “SAY NO” to “Know Your Customer (KYC) policy”
                </StyledSectionTitle>
                <StyledBodyText>
                    Unlike many centralized exchanges, anybody can trade from the hundreds of digital assets, anonymously. Did I mention it&apos;s super simple to use? Continue your read below.
                </StyledBodyText>
                <StyledSectionTitle>
                    Secured and Audited
                </StyledSectionTitle>
                <StyledBodyText>
                    Rissotto has gone above and beyond and is planning audit from three separate security protocols:  Slowmist, Peckshield, and CertiK once the audit slots from these security audit establishments are available. Rissotto has also planned for a bug bounty program to help protect user funds.
                </StyledBodyText>
                <StyledSectionTitle>
                    Is Rissotto Safe?
                </StyledSectionTitle>
                <StyledBodyText>
                    <StyledExternalLink href='https://app.rissotto.finance'>Rissotto Exchange</StyledExternalLink> is extremely safe, as it operates as a decentralized exchange (DEX) and liquidity pool and is built on BNB Binance Smart Chain (BEP-20 - Ethereum), meaning it has the same security as the Ethereum blockchain. Since it is decentralized there is no central server to hack and gain access to users&apos; funds. Whatever funds you provide to a liquidity pool are locked by a smart contract and cannot be removed by any account other than your own, so a hack would be difficult as it would require the hacker to get each individual&apos;s account information to pull anything from the pools. As long as you keep your wallet safe, <StyledExternalLink href='https://app.rissotto.finance'>Rissotto Exchange</StyledExternalLink> is safe to use.
                </StyledBodyText>
                <StyledBodyText>
                    The only potential issues you may run into using <StyledExternalLink href='https://app.rissotto.finance'>Rissotto Exchange</StyledExternalLink> are related to user errors, as the code and smart contracts have been thoroughly audited and have tested safely.
                </StyledBodyText>
                <StyledBodyText>
                    There is also the potential for impermanent loss of assets when you provide liquidity to a pool, and this is true of all liquidity pool protocols, not just Rissotto.
                </StyledBodyText>
            </StyledHowSafe>
        </Layout>
    )
  
}

export default HowSafe